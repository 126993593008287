// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)
Vue.config.productionTip = false

/*
import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor'
Vue.use(RichTextEditorPlugin)

import { DocumentEditorPlugin } from '@syncfusion/ej2-vue-documenteditor';
Vue.use(DocumentEditorPlugin);

import { PdfViewerPlugin } from '@syncfusion/ej2-vue-pdfviewer'
Vue.use(PdfViewerPlugin)

import { DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor';
Vue.use(DocumentEditorContainerPlugin);
*/

import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

import JsonViewer from 'vue-json-viewer/ssr'
Vue.use(JsonViewer)
import 'vue-json-viewer/style.css'

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "UA-126572234-10" }
});

new Vue({
  router,
  store,
  vuetify,
  // RichTextEditorPlugin,
  // DocumentEditorContainerPlugin,
  // PdfViewerPlugin,
  // DocumentEditorPlugin,
  VueFroala,
  i18n,
  render: h => h(App),
}).$mount('#app')
